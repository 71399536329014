








































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { editPartyInfo, idCardExists, personDetails, accountExists, userTypes } from '@/api/system/PersonApi';
import { getCompanyTreeData, getCompanys } from '@/api/system/OrganizationApi';
import DeptAddEdit from './dept-add-edit.vue';
import FileAddEdit from './file-add-edit.vue';
import { checkIDCard } from '@/utils/ValidateUtil';
import DictApi from '@/api/system/DictApi';
import EleUpload from '@/components/form-design/elements/ele-upload.vue';
import _ from 'lodash';
@Component({
  name: 'PartyForm',
  components: { DeptAddEdit, FileAddEdit, EleUpload }
})
export default class PersonForm extends Vue {
  //验证手机号
  validatePhone = (rule, value, callback) => {
    if (!value) {
      callback(new Error(this.$t('lang_please_entry_tel_phone') as any));
    } else if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
      callback(new Error(this.$t('lang_please_enter_the_mobile_number_in_the_correct_format') as any));
    } else {
      callback();
    }
  };

  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  //   当前数据
  @Prop({ default: undefined }) currtent;
  // 传过来的数据
  @Prop({ default: {} }) selectCompanyData;
  // 公司id
  @Prop({ default: [] }) groupIds;

  get _getPartyAge() {
    let age = Number(new Date().getFullYear()) - Number(new Date(this.personData.regularDate).getFullYear());
    if (age <= 100) {
    } else {
      age = 0;
    }
    let month = Number(new Date().getMonth()) - Number(new Date(this.personData.regularDate).getMonth());
    if (month > 0) {
      return age + '年';
    } else if (month == 0) {
      let day = Number(new Date().getDate()) - Number(new Date(this.personData.regularDate).getDate());
      if (day >= 0) {
        return age + '年';
      } else {
        return age - 1 < 0 ? 0 + '年' : age - 1 + '年';
      }
    } else {
      return age - 1 < 0 ? 0 + '年' : age - 1 + '年';
    }
  }
  // 是否显示新增部门信息弹框
  isShowAddEdit: boolean = false;
  //   表单加载动画
  formLoading: boolean = false;
  isShow: boolean = false;
  //   表单数据
  personData: any = {};
  laborContract: any = {
    factEmploymentConfirmDate: '',
    employmentConfirmDate: '',
    period: '',
    invalidDate: ''
  };
  nationList: any = [];
  // 当前选中的部门信息
  deptRow: any = {};
  // 公司下拉数据
  companyList: any[] = [];
  // 部门下拉数据
  deptListData: any[] = [];
  // 职务下拉数据
  dutyListData: any[] = [];
  // 用户类型列表
  userTypeList: any[] = [];
  // 账号规则
  accountSuffix: string = '';
  // 公司id
  companyId: string = '';
  // 部门编辑时用于编辑
  deptIndex: number = null;
  fileIndex: number = null;

  dayEducationList: any = [];
  dayDegreeList: any = [];

  politicalList: any = [];
  proviceList: any[] = [];
  //   验证规则
  formRules: Record<string, any> = {
    groupId: {
      required: true,
      message: this.$t('lang_please_enter'),
      trigger: 'change'
    },
    userType: {
      required: true,
      message: this.$t('lang_please_select_user_type'),
      trigger: 'change'
    },
    name: {
      required: true,
      message: this.$t('lang_please_enter_your_name'),
      trigger: 'blur'
    },
    sex: {
      required: true,
      message: this.$t('lang_please_select_sex'),
      trigger: 'blur'
    },
    idType: {
      required: true,
      message: this.$t('lang_please_select_document_type'),
      trigger: 'blur'
    },
    birthday: {
      required: true,
      message: this.$t('lang_please_entry_birthday'),
      trigger: 'blur'
    },
    idCard: {
      required: true,
      message: this.$t('lang_please_entry_id_card'),
      trigger: 'blur'
    },

    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    }
  };

  isShowAddFileEdit: boolean = false;
  fileRow: any = {};
  fileListData: any[] = [];

  expReminderSelect: string = '1';

  // 图片组件上传地址
  private uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  private pubPreviewUrl: string = '/river-fs/file/view';
  getSelectData() {
    let params1 = {
      typeCode: 'political_outlook'
    };
    DictApi.dictList(params1).then((reg: any) => {
      if (reg.code == '1') {
        this.politicalList = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
  }
  created() {
    this.companyId = this.groupIds[0];
    this.getSelectData();
    // this.getDeptGroupSelectList(this.groupIds[0]);
    // this.getDutySelectList(this.groupIds[0]);
    if (this.currtent) {
      this.getPersonDetail();
    } else {
      this.companyList.push(this.selectCompanyData);
      this.personData = {
        tenantId: this.selectCompanyData.tenantId,
        defaultCompanyId: this.selectCompanyData.id,
        idType: '1',
        user: {
          tenantId: this.selectCompanyData.tenantId
        }
      };
      this.accountSuffix = '@' + this.selectCompanyData.groupCode;
    }
  }
  /**
   * 获取用户类型列表
   */
  getUserTypesList() {
    userTypes().then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.userTypeList = res.data;
      }
    });
  }

  /**
   * 获取详情
   */
  getPersonDetail() {
    let params = {
      companyId: this.groupIds[0],
      personId: this.currtent.id,
      idCard: this.currtent.idCard
    };
    personDetails(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$nextTick(() => {
          let resData = res.data;
          if (resData && resData.takeAnnex) {
            let takeAnnex = [],
              obj = {};
            obj = res.data.takeAnnex;
            takeAnnex.push(obj);
            resData.takeAnnex = takeAnnex;
          }

          this.personData = resData;
          if (res.data.laborContract) {
            this.laborContract = res.data.laborContract;
          }
          if (res.data.user) {
            this.getCompanyList(res.data.user.id);
            // 用户排序
            this.personData.sort = res.data.user.sort;
            // 用户类型
            if (res.data.user.userType) {
              this.personData.userType = res.data.user.userType;
            }
            // 账号
            let aco = res.data.user.account.split('@');
            this.accountSuffix = '@' + aco[1];
            this.$set(this.personData, 'account', aco[0]);
          }
        });
      }
    });
  }

  /**
   * 编辑时获取主公司列表
   */
  getCompanyList(userId) {
    getCompanys({ userId: userId }).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.companyList = res.data;
        this.personData.defaultCompanyId = this.getCompanyId(res.data);
      }
    });
  }

  /**
   * 获取主公司id
   */
  getCompanyId(list) {
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.defaultAble) {
        return a.id;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getCompanyId(a.children);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  /**
   * 账号验证
   */
  accountChange(val) {
    accountExists({ account: this.personData.account + this.accountSuffix }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.$message.error(this.$t('lang_account_number_already_exists_please_re-enter') as any);
      }
    });
  }

  menuTreeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children
    };
    return params;
  }

  /**
   * 新增附件信息
   */
  fileAddClick(row, index) {
    this.fileRow = row;
    if (row) {
      this.fileIndex = index;
    } else if (this.personData.takeAnnex && this.personData.takeAnnex.length) {
      this.$message.error('附件信息仅能有一条');
      return;
    }
    this.isShowAddFileEdit = true;
  }

  // 获取部门下拉列表
  getDeptGroupSelectList(groupIds) {
    getCompanyTreeData({ parentId: groupIds, types: ['0', '1'], cascadeType: '2' })
      .then((res: Record<any, any>) => {
        this.deptListData = res.data;
      })
      .finally(() => {});
  }

  // 获取职务下拉列表
  getDutySelectList(groupIds) {
    getCompanyTreeData({ parentId: groupIds, types: ['2'], cascadeType: '2' })
      .then((res: Record<any, any>) => {
        this.dutyListData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 保存
   */
  save() {
    this.personData.laborContract = this.laborContract;
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      let params = _.cloneDeep(this.personData);

      editPartyInfo(params)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  /**
   * 获取部门名称
   */
  getDeptName(list, id) {
    let _this = this;
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.id === id) {
        return a.name;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getDeptName(a.children, id);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  //获取
  getPoliticalStatus(code) {}

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
