
































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import EleUpload from '@/components/form-design/elements/ele-upload.vue';
@Component({
  name: 'PersonAddEdit',
  components: {
    EleUpload
  }
})
export default class PersonAddEdit extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 当前行信息
  @Prop({ default: undefined }) fileRow;
  // 公司id
  @Prop({ default: '' }) companyId!: string;

  // 表单加载
  formLoading: boolean = false;
  // 表单数据
  fileData: any = {};
  // 表单验证规则
  formRules: Record<string, any> = {
    deptId: [
      {
        required: true,
        message: this.$t('lang_please_select_dept'),
        trigger: 'change'
      }
    ]
  };
  created() {
    if (this.fileRow) {
      this.fileData = { ...this.fileRow };
    } else {
      this.fileData = {
        companyId: this.companyId
      };
    }
  }
  cityChange(value) {
    this.fileData.workProvince = value[1];
    this.fileData.workCity = value[2];
  }

  /**
   * 上传成功
   */
  uploadSuccess() {}

  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      // 关闭弹窗
      this.handleCloseDialog();
      if (this.fileRow) {
        this.$emit('upDone', this.fileData);
      } else {
        this.$emit('done', this.fileData);
      }
    });
  }

  menuTreeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children,
      isDisabled: false
    };
    if (node.type == '0') {
      params.isDisabled = true;
    }
    return params;
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
