




















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PartyForm from './party-form.vue';
import { getPersonPage, deletePerson } from '@/api/system/PersonApi';
import { getCompanyTreeData, getUserCompany, getDeptListData } from '@/api/system/OrganizationApi';
import UserUtil from '@/utils/UserUtil';
import FileApi from '@/api/system/FileApi';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
@Component({
  name: 'PartList',
  components: { PartyForm }
})
export default class PersonList extends Vue {
  // 表单搜索
  formSearchModel: any = { name: '', deptId: null };
  // 表格加载动画
  loadingOuter: boolean = false;
  // 表格数据
  formListData: any[] = [];
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  //   是否显示新增编辑弹框
  isShowAddEdit: boolean = false;
  //   当前选中的数据
  currtent: any = null;
  // 公司树列表
  companyData: any[] = [];
  // 当前树选中的gruopId
  groupIds: any[] = [];
  // 选中的公司数据
  selectCompanyData: any = {};
  //选中公司的部门
  depts: any = [];
  // 是否显示新增按钮
  showAddFlag: boolean = false;
  // 当前选中的左侧树节点
  currentNodeKey: string = '';

  dLoading: boolean = false;

  token: any = null;
  created() {
    this.token = StorageUtil.getSessionStorage('token');
  }

  mounted() {
    this.getParentDeptGroupSelectList();
    this.getUserCompany();
  }

  @Watch('currentNodeKey', { deep: true })
  currentNodeKeyWatcher(id) {
    if (id.toString()) {
      (this.$refs['appTreeRef'] as any).setCurrentKey(id);
    } else {
      (this.$refs['appTreeRef'] as any).setCurrentKey(null);
    }
  }

  /**
   * 获取当前登录人的公司信息
   */
  async getUserCompany() {
    this.groupIds = [];
    let params = {
      tenantId: UserUtil.getUser().tenantId,
      userId: UserUtil.getUser().id
    };
    let { data } = await getUserCompany(params);
    if (data.length > 0) {
      this.currentNodeKey = data[0].id;
      this.groupIds.push(data[0].id);
      this.getdeptList(data[0].id);
      // 左侧树选中后 赋予默认值
      this.selectCompanyData = { ...data[0] };
      this.showAddFlag = true;
    }
    this.getPersonPage();
  }
  /**
   * 获取人员分页数据
   */
  getPersonPage() {
    let params = {
      name: this.formSearchModel.name,
      deptId: this.formSearchModel.deptId,
      groupIds: this.groupIds,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.loadingOuter = true;
    getPersonPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.formListData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.loadingOuter = false;
      });
  }

  // 获取上级机构组下拉列表
  getParentDeptGroupSelectList() {
    getCompanyTreeData({ type: '0' })
      .then((res: Record<any, any>) => {
        this.companyData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    this.groupIds = [];
    this.selectCompanyData = { ...data };
    if (data.id) {
      this.showAddFlag = true;
      this.groupIds.push(data.id);
      this.pageIndex = 1;
      this.formSearchModel.deptId = null;
      this.getPersonPage();
      this.getdeptList(data.id);
    }
  }

  // 部门列表
  getdeptList(parentId: string) {
    getCompanyTreeData({ parentId: parentId, type: '1', cascadeType: '2' })
      .then((res: Record<any, any>) => {
        this.depts = res.data;
      })
      .finally(() => {});
  }

  treeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children,
      isDisabled: false
    };
    if (node.type == '0') {
      params.isDisabled = true;
    }
    return params;
  }

  /**
   * 删除
   */
  remove(row?: any) {
    deletePerson({ personId: row.id }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getPersonPage();
      }
    });
  }

  /**
   * 点击搜索
   */
  handleSearch() {
    this.pageIndex = 1;
    this.getPersonPage();
  }

  /**
   * 重置
   */
  handleSearchReset() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getPersonPage();
  }

  /**
   * 点击新增
   */
  handleAdd(row: any) {
    this.currtent = row;
    this.isShowAddEdit = true;
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getPersonPage();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getPersonPage();
  }

  getAge(partyJoinTime) {
    if (!partyJoinTime) return '';
    let age = Number(new Date().getFullYear()) - Number(new Date(partyJoinTime).getFullYear());
    if (age <= 100) {
    } else {
      age = 0;
    }
    let month = Number(new Date().getMonth()) - Number(new Date(partyJoinTime).getMonth());
    if (month > 0) {
      return age + '年';
    } else if (month == 0) {
      let day = Number(new Date().getDate()) - Number(new Date(partyJoinTime).getDate());
      if (day >= 0) {
        return age + '年';
      } else {
        return age - 1 < 0 ? 0 + '年' : age - 1 + '年';
      }
    } else {
      return age - 1 < 0 ? 0 + '年' : age - 1 + '年';
    }
  }
}
